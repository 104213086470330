import { environment } from './../../../environments/environment';
import { inject, Injectable } from '@angular/core';
import { Configuration, DataleanBaseApiService } from 'addiction-components';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {
  protected baseApi = inject(DataleanBaseApiService);

  getConfigsByKey(configKey: string) {
    return this.baseApi.getEntities<Configuration[]>(`${environment.configUrl}/${configKey}`, {}, [])
  }
}
